import { useEffect, useRef, useState } from 'react'
import { Container, Wrapper, MainRow, SitesWrapper, SideBar, SitesInfoWrapper, DeploySiteModal, SiteBuilds, DeployLogModal, BuildWrapper, LogWrapper, LogsModal, GraphWrapper, GrapContainer, GraphBox, MountingWrapper, SettingsWrapper, DeleteModal } from './styles'
import AxiosCall from '../../../../../utils/axios'
import Message from '../../../../components/message/Message'
import { useNavigate, useParams } from 'react-router'
import { HiExternalLink } from 'react-icons/hi'
import dayjs from 'dayjs'
import Loader from '../../../../components/Loader/Loader'
import { AiOutlineBranches, AiOutlineDeploymentUnit } from 'react-icons/ai'
import { BsFillTrashFill, BsGithub } from 'react-icons/bs'
import PopupModal from '../../../../components/popupModal/PopupModal'
import { FaExpand, FaFileCode } from 'react-icons/fa'
import { EmptyContent } from '../styles'
import {Line} from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler, } from 'chart.js'
import { defaultChartState, options, resolveDataInStats, resolveDataOutStats, resolveRequestInStats } from './actions'
import formatNumber from '../../../../../utils/numberFormatter'
import { IoWarning } from 'react-icons/io5'
import { useAppDispatch } from '../../../../../hooks/hooks'
import { getApps } from '../../../../../actions/appActions'
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
  )
  

const SiteInfoScreen: React.FC = () => {
    const [byteSentData, setByteSentData] = useState(defaultChartState)
    const [byteReceivedData, setByteReceivedData] = useState(defaultChartState)
    const [requestsIn, setRequestsIn] = useState(defaultChartState)
    const [stats, setStats] = useState({
        dataIn: {
            min: 0,
            max: 0,
            avg: 0,
            last: 0
        },
        dataOut: {
            min: 0,
            max: 0,
            avg: 0,
            last: 0
        },
        requestIn: {
            min: 0,
            max: 0,
            avg: 0,
            last: 0
        },
    })
    const [isLoadingStats, setIsLoadingStats] = useState(true)
    const [appInfo, setAppInfo] = useState<any>()
    const [appDeployInfo, setAppDeployInfo] = useState<any>(null)
    const [appMeta, setAppMeta] = useState<any>(null)
    const [isFetchingApp, setIsFetchingApp] = useState(false)
    const [isUpdatingEnv, setIsUpdatingEnv] = useState(false)
    const [envList, setEnvList] = useState<any[]>([])
    const [showLogModal, setShowLogModal] = useState(false)
    const [showDeletePebble, setShowDeletePebble] = useState(false)
    const [selectedBuild, setSelectedBuild] = useState<any>(null)
    const [expandLogs, setExpandLogs] = useState<boolean>(false)
    const [logs, setLogs] = useState<string | null>(null)
    const [isFetchingLogs, setIsFetchingLogs] = useState<boolean>(false)
    const [showProgressIndicator, setShowProgressIndicator] = useState(false)
    const [progressStatus, setProgressStatus] = useState("0%")

    const { appId } = useParams()

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [builds, setBuilds] = useState<any[]>([])

    const navigate = useNavigate()
    const dispatch = useAppDispatch()


    const [deletePebbleSlug, setDeletePebbleSlug] = useState("")
    const [password, setPassword] = useState("")

    const [isDeletingPebble, setIsDeletingPeble] = useState(false)
    const deletePebble = async (e: any) => {
        try {
            e.preventDefault()
            if (isDeletingPebble) {
                return;
            }
            setIsDeletingPeble(true);
            
            const res: any = await AxiosCall({
                method: "POST",
                path: "/v1/app/delete/" + appId,
                data: {
                    password: password
                }
            });

            setIsDeletingPeble(false)
            if (res.status == 1) {
                navigate(-1)

                getApps(dispatch)
                Message.success("Pebble deleted successfullt")
            } else {
                Message.error(res.message)
            }

        } catch (err: any) {
            setIsDeletingPeble(false)
            Message.error(err?.response?.data?.message ?? "An error occurred")
        }
    }

    const getBuilds = async () => {
        try {
            setIsLoading(true)
            
            const res: any = await AxiosCall({
                method: "GET",
                path: `/v1/app/${appId}/builds/fetch`
            });

            setIsLoading(false)
            if (res.status == 1) {
                setBuilds(res.data);
            } else {
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsLoading(false)
            Message.error(err?.response?.data?.message ?? "An error occurred")
        }
    }

    const fetchAppStats = async () => {
        try {
            setIsLoadingStats(true)
            
            const res: any = await AxiosCall({
                method: "GET",
                path: `/v1/app/stats/fetch/${appId}`
            });

            if (res.status == 1) {

                let _data_out_stats = stats.dataOut
                let _data_in_stats = stats.dataOut
                let _request_in_stats = stats.dataOut

                if (res.data.total_sent_dataset.length) {
                    const _data_out = resolveDataOutStats(res.data.total_sent_dataset, appId!, res.data.server_hour)
                    setByteSentData(_data_out.state)

                    _data_out_stats = {
                        min: _data_out.min ?? 0,
                        max: _data_out.max ?? 0,
                        avg: _data_out.avg ?? 0,
                        last: _data_out.last ?? 0
                    }
                }
                

                if (res.data.total_received_dataset.length) {
                    const _data_in = resolveDataInStats(res.data.total_received_dataset, appId!, res.data.server_hour)
                    setByteReceivedData(_data_in.state)
                    _data_in_stats = {
                        min: _data_in.min ?? 0,
                        max: _data_in.max ?? 0,
                        avg: _data_in.avg ?? 0,
                        last: _data_in.last ?? 0
                    }
                }

                if (res.data.total_requests_dataset.length) {
                    const _requests_in = resolveRequestInStats(res.data.total_requests_dataset, appId!, res.data.server_hour)
                    setRequestsIn(_requests_in.state)
                    _request_in_stats = {
                        min: _requests_in.min ?? 0,
                        max: _requests_in.max ?? 0,
                        avg: _requests_in.avg ?? 0,
                        last: _requests_in.last ?? 0
                    }
                }

                setStats({
                    dataIn: _data_in_stats,
                    dataOut: _data_out_stats,
                    requestIn: _request_in_stats,
                })
                setIsLoadingStats(false)
            } else {
                Message.error(res.message)
            }
        } catch (err: any) {
            console.log(err)
            setIsLoadingStats(false)
            Message.error(err?.response?.data?.message ?? "An error occurred while fetching app stats")
        }
    }

    const getAppLogs = async () => {
        try {
            setIsFetchingLogs(true)
            
            const res: any = await AxiosCall({
                method: "GET",
                path: `/v1/app/logs/fetch/${appId}`
            });

            setIsFetchingLogs(false)
            if (res.status == 1) {
                setLogs(res.data);
            } else {
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsFetchingLogs(false)
            Message.error(err?.response?.data?.message ?? "An error occurred")
        }
    }

    const [deploySiteModal, setDeploySiteModal] = useState(false)

    const updateSiteDeployment = (e: any) => {
        e.preventDefault();
        setDeploySiteModal(true)
        localStorage.setItem("new_app", appInfo?.slug)
    }

    const deploySite = (e: any) => {
        e.preventDefault()

        window.location.assign("https://github.com/apps/coralscale/installations/new")
    }

   

    useEffect(() => {
        fetchAppStats()
    }, [])

    const [isDeploying, setIsDeploying] = useState(false)
    const triggerDeploy = async () => {
        try {
            if (isDeploying) {
                return;
            }
            setIsDeploying(true);
            
            setTimeout(() => {
                Message.success("Deploy triggered successully")
                setIsDeploying(false);
            }, 2000);
            const res: any = await AxiosCall({
                method: "POST",
                path: "/v1/app/github/deploy/trigger?appSlug="+appId
            });

        } catch (err: any) {
            
        }
    }
    
    const fetchAppRef = useRef<any>();
    const getFetchApp = async () => {
        try {
            setIsFetchingApp(true)
            
            const res: any = await AxiosCall({
                method: "GET",
                path: "/v1/app/fetch/"+appId
            });

            setIsFetchingApp(false)
            if (res.status == 1) {
                if (appInfo == null) {
                    setAppInfo(res.data.app)
                }

                const _app = res.data.app

                if (_app.progress) {
                    if (!showProgressIndicator) {
                        setShowProgressIndicator(true)
                    }
                    setProgressStatus(_app.progress)
                    fetchAppRef.current = setTimeout(() => {
                        getFetchApp()
                    }, 10000)
                } else {
                    clearTimeout(fetchAppRef.current);
                    setProgressStatus("100%")
                    setTimeout(() => {
                        setShowProgressIndicator(false)
                    }, 1000);
                }

                const app_meta = JSON.parse(res.data.app.meta)

                setAppMeta(app_meta);
                setAppDeployInfo(res.data.deploy)

                let new_env = []
                for (let index = 0; index < res.data.deploy.env.length; index++) {
                    const env = res.data.deploy.env[index];
                    new_env.push({
                        key: Object.keys(env)[0],
                        value: env[Object.keys(env)[0]]
                    })
                }
                setEnvList([...new_env])
            } else {
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsFetchingApp(false)
            Message.error(err?.response?.data?.message ?? "An error occurred")
        }
    }


    const updateAppEnv = async () => {
        try {
            if (isUpdatingEnv) {
                return;
            }
            setIsUpdatingEnv(true)
            
            const res: any = await AxiosCall({
                method: "PATCH",
                path: "/v1/app/env/update/"+appId,
                data: {
                    env: envList
                }
            });
            
            setIsUpdatingEnv(false)
        
            if (res.status == 1) {
                Message.success(res.message)
            } else {
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsUpdatingEnv(false)
            Message.error(err?.response?.data?.message ?? "An error occurred")
        }
    }

    const addEnvKey = () => {
        let env = envList;
        setEnvList([...envList, {
            key: "",
            value: ""
        }])
    }

    const updateEnvKey = ({idx, key, value} : {idx: number, key: string, value: string}) => {
        let env = envList;
        env[idx].key = key
        env[idx].value = value
        setEnvList([...env])
    }

    useEffect(() => {
        getFetchApp()
    }, [])


    useEffect(() => {
        getBuilds()
    }, [])


    return (
        <Wrapper>
            <Container>
                <MainRow>
                    <SitesInfoWrapper>
                        <SitesWrapper>
                            <div className="head">
                                <div className="col">
                                    <h5>{appInfo?.name}</h5>
                                    <div className="row">
                                        <b>status: </b>
                                        <span className={"" + appInfo?.status}>{appInfo?.status}</span>
                                    </div>
                                </div>
                                <button onClick={triggerDeploy}><span>{isDeploying ? <Loader /> : "trigger deploy"}</span></button>
                            </div>

                            {showProgressIndicator && <MountingWrapper progress={progressStatus} />}

                            <div className="content">
                                <h2>General information</h2>
                                <ul>
                                    <b>Pebble url</b>
                                    {appInfo && <li><a href={"https://" +appInfo?.domain} target='_blank'>{"https://"+appInfo?.domain} <HiExternalLink /></a></li>}
                                </ul>
                                <ul>
                                    <b>Stack</b>
                                    <li>{appMeta?.stack}</li>
                                </ul>
                                <ul>
                                    <b>Date created</b>
                                    {appInfo && <li>{dayjs(appInfo?.date_created).format("MMM DD,  YYYY")}</li>}
                                </ul>
                                <ul>
                                    <b>last deploy on</b>
                                    <li></li>
                                </ul>
                            </div>
                        </SitesWrapper>

                        <SitesWrapper>
                            <GraphWrapper>
                                <GrapContainer color="#00991f66">
                                    <span className="title">Data sent (MB)</span>
                                
                                    <GraphBox background="#00991f11">
                                        {isLoadingStats ? <Loader center styleTwo /> : byteSentData.labels.length ? <Line
                                            style={{ height: "320px" }}
                                            data={byteSentData}
                                            options={options}
                                        /> : <></>}
                                    </GraphBox>
                                    

                                    <div className="stats">
                                        <ul>
                                            <li></li>
                                            <li>Max</li>
                                            <li>Avg</li>
                                            <li>Last</li>
                                        </ul>
                                        <ul>
                                            <li>Data sent (MB)</li>
                                            <li>{formatNumber(stats.dataOut.max).toSize()}</li>
                                            <li>{formatNumber(stats.dataOut.avg).toSize()}</li>
                                            <li>{formatNumber(stats.dataOut.last).toSize()}</li>
                                        </ul>
                                    </div>
                                </GrapContainer>


                                <GrapContainer color="#99120066">
                                    <span className="title">Data received (MB)</span>
                                
                                    <GraphBox background="#99120011">
                                        {isLoadingStats ? <Loader center styleTwo /> : byteReceivedData.labels.length ? <Line
                                            style={{ height: "320px" }}
                                            data={byteReceivedData}
                                            options={options}
                                        /> : <></>}
                                    </GraphBox>
                                    

                                    <div className="stats">
                                        <ul>
                                            <li></li>
                                            <li>Max</li>
                                            <li>Avg</li>
                                            <li>Last</li>
                                        </ul>
                                        <ul>
                                            <li>Data In (MB)</li>
                                            <li>{formatNumber(stats.dataIn.max).toSize()}</li>
                                            <li>{formatNumber(stats.dataIn.avg).toSize()}</li>
                                            <li>{formatNumber(stats.dataIn.last).toSize()}</li>
                                        </ul>
                                    </div>
                                </GrapContainer>


                                <GrapContainer color="#99009966">
                                    <span className="title">Request Received</span>
                                
                                    <GraphBox background="#99009911">
                                        {isLoadingStats ? <Loader center styleTwo /> : <>{requestsIn.labels.length ? <Line
                                            style={{ height: "320px" }}
                                            data={requestsIn}
                                            options={options}
                                        /> : <></>}</>}
                                    </GraphBox>
                                    

                                    <div className="stats">
                                        <ul>
                                            <li></li>
                                            <li>Max</li>
                                            <li>Avg</li>
                                            <li>Last</li>
                                        </ul>
                                        <ul>
                                            <li>Requests In </li>
                                            <li>{formatNumber(stats.requestIn.max).toShortForm()}</li>
                                            <li>{formatNumber(stats.requestIn.avg).toShortForm()}</li>
                                            <li>{formatNumber(stats.requestIn.last).toShortForm()}</li>
                                        </ul>
                                    </div>
                                </GrapContainer>
                            </GraphWrapper>
                        </SitesWrapper>

                        <SettingsWrapper>
                            <div className="head">
                                <h2>Settings</h2>
                                <div className="action">
                                    <button onClick={() => setShowDeletePebble(true)}>
                                        <span>delete</span>
                                    </button>
                                </div>
                            </div>
                            <div className="content">
                                <ul>
                                    <b>Deploys from: Github</b>
                                    <div className="deploy-meta">
                                        <li><BsGithub /> {appDeployInfo?.repo_owner}/{appDeployInfo?.repo_name}</li>
                                        <li><AiOutlineBranches /><span>{appDeployInfo?.deploy_branch}</span></li>
                                    
                                        <button onClick={updateSiteDeployment}>update</button>
                                    </div>
                                </ul>

                                <ul>
                                    <b>Root path</b>
                                    <div className="row">
                                        <input type="text" placeholder='/' value={appMeta?.root_path ?? ""}  onChange={(e) => {}} />
                                        <button>update</button>
                                    </div>
                                </ul>
                                <ul>
                                    <b>Slug</b>
                                    <div className="row">
                                        <input type="text" placeholder='kelvin-app' defaultValue={appInfo?.slug ?? ""} onChange={(e) => {}} />
                                        <button>update</button>
                                    </div>
                                </ul>
                            </div>
                        </SettingsWrapper>

                        <SitesWrapper>
                            <div className="head">
                                <div className="col">
                                    <h5>Environment variables</h5>
                                </div>
                                <button onClick={() => { updateAppEnv() }}>{isUpdatingEnv ? <Loader /> : <span>Save Changes</span>}</button>
                            </div>

                            <div className="content">
                                <div className="env-container">
                                    {envList.map((item, idx) =>{
                                        return <ul key={"env-"+idx}>
                                            <input type="text"  placeholder={"KEY"} onChange={(e) => updateEnvKey({idx: idx, key: e.target.value, value: item.value})} defaultValue={item.key} />
                                            <span>=</span>
                                            <input type="text"  placeholder={"VALUE"}  onChange={(e) => updateEnvKey({idx: idx, key: item.key, value: e.target.value})} defaultValue={item.value}/>
                                        </ul>
                                    })}

                                    <div className="actions">
                                        <button onClick={addEnvKey}>add key</button>
                                    </div>
                                </div>
                            </div>
                        </SitesWrapper>
                    </SitesInfoWrapper>
                    

                    <SideBar>
                        <BuildWrapper>
                            <div className="head">
                                <h5>Recent builds</h5>
                            </div>

                            <div className="content">
                                {isLoading ? <Loader styleTwo center topPadding="40px" /> : !builds.length ? <EmptyContent>
                                    <div className="text-content">
                                        <AiOutlineDeploymentUnit />
                                        <span>You have no builds yet</span>
                                        <p>Updates to your application appear here and can be tracked using their status.</p>
                                    </div>
                                </EmptyContent> :  builds.map((item: any, idx) => {
                                    return <SiteBuilds status={item.status} key={"apps-"+idx} onClick={() => {setSelectedBuild(item); setShowLogModal(true)}}>
                                        <div className="img-wrapper">
                                            <AiOutlineDeploymentUnit />
                                        </div>
                                        <div className="col">
                                            <h4>Build - #{item?.session}</h4>
                                            <span>{dayjs(item?.date_created).format("MMM DD,  YYYY")} &nbsp;  - &nbsp;   {dayjs(item?.date_created).format("hh:ma")}</span>
                                        </div>
                                        <div className="meta">
                                            <span>{item?.status}</span>
                                            {/* <span>Deployed: July 3rd  2023</span> */}
                                        </div>
                                    </SiteBuilds>
                                })}
                            </div>
                        </BuildWrapper>

                        <LogWrapper>
                            <div className="head">
                                <h5>Recent Logs</h5>
                                <div className="expand" onClick={() => {setExpandLogs(true)}}><FaExpand /></div>
                            </div>

                            {logs == null ? <EmptyContent>
                                <div className="text-content">
                                    <FaFileCode />
                                    <span>Application logs</span>
                                    <p>Application logs helps in testing and debuging errors. Last 100 lines of logs would be displayed here.</p>
                                </div>
                                <button onClick={() => getAppLogs()}>{isFetchingLogs ? <Loader /> : "fetch logs"}</button>
                            </EmptyContent> :
                            <div className="content">
                                <div className="logs-wrapper">
                                    <div className="logs">
                                        <p dangerouslySetInnerHTML={{__html: logs}}></p>
                                    </div>
                                </div>
                            </div>}
                        </LogWrapper>
                    </SideBar>
                </MainRow>
            </Container>

            <PopupModal showPopup={deploySiteModal} closeModal={() => setDeploySiteModal(false)}>
                <DeploySiteModal>
                    <h4>Select deployment mode</h4>
                    <button onClick={deploySite}>Deploy from github</button>
                </DeploySiteModal>
            </PopupModal>

            <PopupModal maxWidth="640px" showPopup={expandLogs} closeModal={() => setExpandLogs(false)}>
                <LogsModal>
                    <h4>App logs</h4>
                    {logs == null ? <EmptyContent>
                        <div className="text-content">
                            <FaFileCode />
                            <span>Application logs</span>
                            <p>Application logs helps in testing and debuging errors. Last 100 lines of logs would be displayed here.</p>
                        </div>
                        <button onClick={() => getAppLogs()}>{isFetchingLogs ? <Loader /> : "fetch logs"}</button>
                    </EmptyContent> :
                    <div className="content">
                        <div className="logs-wrapper">
                            <div className="logs">
                                <p dangerouslySetInnerHTML={{__html: logs}}></p>
                            </div>
                        </div>
                    </div>}
                </LogsModal>
            </PopupModal>

            <PopupModal maxWidth="640px" showPopup={showLogModal} closeModal={() => setShowLogModal(false)}>
                <h4>Deploy logs</h4>
                <DeployLogModal dangerouslySetInnerHTML={{__html: selectedBuild?.log}} />
            </PopupModal>

            <PopupModal maxWidth="480px" showPopup={showDeletePebble} closeModal={() => setShowDeletePebble(false)}>
                <DeleteModal>
                    <div className="icon-box">
                        <IoWarning />
                    </div>
                    <h4>Delete pebble</h4>
                    <p className="warning">Are you sure you want to delete this pebble? Kindly note that this action is irreversible!</p>

                    <div className="notice">
                        <p>Enter your pebble slug <span>{appInfo?.slug ?? ""}</span> to continue</p>
                    </div>

                    <input type="search" autoComplete="off" name="app-slug" placeholder="Enter slug" onChange={(e) => setDeletePebbleSlug(e.target.value)} />
                    {/* <input name="acct-password" type="password" autoComplete="off" placeholder="Enter password" onChange={(e) => setPassword(e.target.value)} /> */}
                    <button disabled={deletePebbleSlug != appInfo?.slug} onClick={deletePebble}>{isDeletingPebble ? <Loader /> : "Delete"}</button>
                </DeleteModal>
            </PopupModal>
        </Wrapper>
    )
}

export default SiteInfoScreen