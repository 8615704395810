import styled from 'styled-components'

export const HeaderWrapper = styled.header`
    display: block;
    position: fixed;
    width: 100%;
    top: 0px;
    right: 0px;
    height: 70px;
    z-index: 9999;
    padding: 0px 6%;
    background-color: ${props => props.theme.backgroudColor};

    @media screen and (min-width: 880px) {
        padding: 0px 4%;
        width: calc(100% - 240px);
    }
`;
export const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    gap: 20px;

    .toggle-menu {
        display: block;
        font-size: 23px;
        color: ${props => props.theme.darkTextColor};
        cursor: pointer;
    }

    .left-col {
        display: flex;
        align-items: center;
        gap: 20px;
        flex: 1;

        .search-bar {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 20px 15px;
            background-color: ${props => props.theme.containerBackground};
            border: 1px solid ${props => props.theme.lightBorderColor};
            height: 38px;
            border-radius: 4px;
            max-width: 180px;
            flex: 1;

            @media screen and (min-width: 480px) {
                max-width: 250px;
            }

            @media screen and (min-width: 880px) {
                max-width: 400px;
            }

            svg {
                color: ${props => props.theme.lightTextColor};
            }

            input {
                min-width: 10px;
                border: none;
                flex: 1;
                background-color: transparent;
                color: ${props => props.theme.textColor};
            }
        }
    }

    .right-col {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;
        flex: 1;

        .create-btn {
            display: flex;
            align-items: center;
            height: 36px;
            gap: 4px;
            border: none;
            background-color: ${props => props.theme.primaryButtonColor};
            color: ${props => props.theme.primaryButtonTextColor};
            padding: 0px 12px;
            border-radius: 2px;
            cursor: pointer;
            
            svg {
                font-size: 17px;
                margin-top: 2px;
            }
        }
    }

    @media screen and (min-width: 880px) {
        .toggle-menu {
            display: none;
        }
    }
`;
export const Title = styled.h5`
    display: none;
    font-size: 14px;
    font-weight: 600;
    color: #0E141C;

    @media screen and (min-width: 880px) {
        display: none;
        font-size: 17px;
    }
`;
export const HeadMeta = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    svg {
        font-size: 16px;
        color: ${props => props.theme.darkTextColor};
    }

    .acct-info {
        display: none;
        flex-direction: column;
        align-items: flex-start;

        span {
            color: ${props => props.theme.darkTextColor};
            font-size: 13px;
            font-weight: 400;

        }

        @media screen and (min-width: 640px) {
            display: flex;
        }
    }
    
    .notification-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        border: 1px solid ${props => props.theme.lightPrimaryColor};
        background-color: ${props => props.theme.containerBackground};
        overflow: hidden;

        svg {
            font-size: 22px;
            color: ${props => props.theme.textPrimaryColor};
            font-size: 30px;
        }

        img {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            object-fit: cover;
        }
    }
`;