import {createSlice} from '@reduxjs/toolkit';

const initialState: {state: AppSliceInterface} = {
  state: {
    apps: [],
    isLoading: true,
    fetchedSites: false,
  }
};

export const setAppSlice = createSlice({
  name: 'sites',
  initialState,
  reducers: {
    setSites: (state, action) => {
      state.state = {...state.state, ...action.payload};
    },
  },
});

export const {setSites: setApps} = setAppSlice.actions;

export interface AppSliceInterface {
  apps: {
    id: string
    user_id: string
    name: string
    description: string
    domain: string
    slug: string
    stack: string
    deploy_mode: string
    meta: string
    status: string
    server_address: string
    date_created: string
    date_updated: string
  }[]
  fetchedSites: boolean,
  isLoading: boolean,
}

export default setAppSlice.reducer;
