import Button from '../../../../../components/button/Button'
import { InviteMemberModal, Container, MemberInfoModal, Form, InputSec, MainRow, Members, MembersList, SideBarContainer, SitesInfoWrapper, SitesWrapper, Wrapper } from './styles'
import { MdAddCircleOutline } from 'react-icons/md'
import { useEffect, useRef, useState } from 'react'
import PopupModal from '../../../../../components/popupModal/PopupModal'
import Message from '../../../../../components/message/Message'
import AxiosCall from '../../../../../../utils/axios'
import { AppSliceInterface } from '../../../../../../slices/appSlice'
import { useAppSelector } from '../../../../../../hooks/hooks'
import Dropdown from '../../../../../components/dropdown/Dropdown'
import { FaSave, FaUserAlt } from 'react-icons/fa'
import Loader from '../../../../../components/Loader/Loader'
import { IoMdClose } from 'react-icons/io'
import { RiUserAddFill } from 'react-icons/ri'

const SettingsTab: React.FC = () => {
    const [showInviteMemberModal, setShowInviteMemberModal] = useState(false)
    const [showUpdateMemberModal, setShowUpdateMemberModal] = useState(false)
    const [isFetchingDomains, setIsFetchingDomains] = useState(true)
    const [isSearchingMember, setIsSearchingMember] = useState(false)
    const [searchedMember, setSearchedMember] = useState<any>(null)
    const [searchList, setSearchList] = useState<any[]>([])
    const [selectedVolume, setSelectedVolume] = useState<any>(null)
    const [volumeList, setVolumeList] = useState<any[]>([])
    const [isAddingVolume, setIsAddingVolume] = useState(false)
    const [isDeletingVolume, setIsDeletingVolume] = useState(false)
    
    const permissionLevels = [
        "Administrator",
        "Member",
    ]

    const storagePathRef = useRef<HTMLInputElement>(null)
    const appSlugRef = useRef<HTMLSelectElement>(null)

    const fetchVolumes = async () => {
        try {
            const res: any = await AxiosCall({
                method: "GET",
                path: "/v1/volumes/fetch"
            });

            setIsFetchingDomains(false)

            if (res.status == 1) {
                setVolumeList(res.data ?? [])
            } else {
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsFetchingDomains(false)
            Message.error(err?.response?.data?.message ?? "An error occurred while fetching domains")
        }
    }

    const addVolume = async () => {
        try {

            return

            setIsAddingVolume(true)

            const res: any = await AxiosCall({
                method: "POST",
                path: "/v1/volume/add",
                data: {
                    path: storagePathRef?.current?.value,
                    app_slug: appSlugRef?.current?.value,
                }
            });

            if (res.status == 1) {
                setIsAddingVolume(false)
                setShowInviteMemberModal(false)
                fetchVolumes()
                Message.success("Volume created successfully")
            } else {
                setIsAddingVolume(false)
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsAddingVolume(false)
            Message.error(err?.response?.data?.message ?? "An error occurred")
        }
    }

    const deleteVolume = async () => {
        try {
            setIsDeletingVolume(true)

            const res: any = await AxiosCall({
                method: "DELETE",
                path: "/v1/volume/delete/",
                data: {
                    volume_path: selectedVolume?.path,
                    app_slug: selectedVolume?.app_slug,
                }
            });

            if (res.status == 1) {
                setIsDeletingVolume(false)
                setShowUpdateMemberModal(false)
                setSelectedVolume(null)
                const _volumeList = [...volumeList]
                const filteredDomain = _volumeList.filter((item: any) => item.domain_name == selectedVolume?.domain_name)
                const volumeIndex = _volumeList.indexOf(filteredDomain[0])
                _volumeList.splice(volumeIndex, 1)
                setVolumeList([..._volumeList!])

                Message.success("Volume deleted successfully")
            } else {
                setIsDeletingVolume(false)
                Message.error(res.message)
            }
        } catch (err: any) {
            console.log(err);
            
            setIsDeletingVolume(false)
            Message.error(err?.response?.data?.message ?? "An error occurred")
        }
    }

    useEffect(() => {
        fetchVolumes()
    }, [])

    return (
        <Wrapper>
            <MainRow>
                <Container>
                    <SitesInfoWrapper>
                        <div className="head">
                            <h5>Project Information</h5>

                            <button className="add-site" onClick={() => setShowInviteMemberModal(true)}>
                                <FaSave />
                                <span>Update</span>
                            </button>
                        </div>
                        <SitesWrapper>
                            <Form>
                                <InputSec>
                                    <label htmlFor="">Project name</label>
                                    <input type="text" placeholder="Enter name" />
                                </InputSec>

                                <InputSec>
                                    <label htmlFor="">Project Description</label>
                                    <input type="text" placeholder="Enter description" />
                                </InputSec>

                                <InputSec>
                                    <label htmlFor="">Purpose</label>
                                    <Dropdown placeHolder="Select purpose" options={[
                                        "Just trying out coralscale",
                                        "Educational purposes",
                                        "Website or blog",
                                        "Web Application",
                                        "Ecommerce"
                                    ]} />
                                </InputSec>
                            </Form>
                        </SitesWrapper>
                    </SitesInfoWrapper>

                    <SitesInfoWrapper>
                        <div className="head">
                            <h5>Members</h5>

                            <button className="add-site" onClick={() => setShowInviteMemberModal(true)}>
                                <MdAddCircleOutline />
                                <span>Invite</span>
                            </button>
                        </div>
                        <SitesWrapper>
                            <MembersList>
                                {[1,2,3,4,5,6].map((item, idx) => {
                                    return <Members>
                                        <div className="status-indicator">
                                            <FaUserAlt />
                                        </div>
                                        <div className="info">
                                            <h3>Hashirama Senju</h3>
                                            <span>Member</span>
                                        </div>
                                        <button onClick={() => setShowUpdateMemberModal(true)}>update</button>
                                    </Members>
                                })}
                            </MembersList>
                        </SitesWrapper>
                    </SitesInfoWrapper>
                </Container>

                <SideBarContainer>
                    <div className="head">
                        <h5>Recent Activity</h5>
                    </div>
                    
                </SideBarContainer>
            </MainRow>

            <PopupModal showPopup={showInviteMemberModal} closeModal={() => setShowInviteMemberModal(false)}>
                <InviteMemberModal>
                    <div className="head">
                        {/* <div className="img-wrapper">
                            <RiUserAddFill />
                        </div> */}
                        <h4>Invite Member</h4>
                    </div>

                    <div className="search-member">
                        <input type="text" placeholder="Enter email" />
                        {isSearchingMember && <Loader />}
                    </div>

                    {searchedMember && <div className="selected-member">
                        <div className="info">
                            <h3>John Doe</h3>
                            <span>john@gmail.com</span>
                        </div>

                        <IoMdClose />
                    </div>}

                    {searchList.length ? <ul className="search-list">
                        {searchList.map((item, idx) => {
                            return <li key={idx}>
                                <div className="info">
                                    <h3>John Doe</h3>
                                    <span>john@gmail.com</span>
                                </div>

                                <b>invite</b>
                            </li>
                        })}
                    </ul> : <></>}
                    
                    <Dropdown placeHolder="-- Select role --" options={permissionLevels} />
                    <Button onClick={() => addVolume()} isLoading={isAddingVolume}>Invite</Button>
                </InviteMemberModal>
            </PopupModal>

            <PopupModal closeModal={() => setShowUpdateMemberModal(false)}  showPopup={showUpdateMemberModal}>
                <MemberInfoModal>
                    <h4>Member Infomation</h4>

                    <ul className="info">
                        <li>
                            <span>Name: </span>
                            <span>John Soleman</span>
                        </li>
                        <li>
                            <span>Email Address: </span>
                            <span>Suleman@gmail.com</span>
                        </li>
                        <Dropdown placeHolder="-- Select role --" options={permissionLevels} />
                    </ul>

                    <div className="button-row">
                        <button onClick={() => {setShowUpdateMemberModal(false)}}>Delete</button>
                        <Button onClick={() => deleteVolume()} isLoading={isDeletingVolume}>Update</Button>
                    </div>
                </MemberInfoModal>
            </PopupModal>
        </Wrapper>
    )
}

export default SettingsTab