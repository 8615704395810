import { setApps } from "../slices/appSlice";
import AxiosCall from "../utils/axios";
import Message from "../views/components/message/Message";


export const getApps = async (dispatch: Function) => {
    try {
        const res: any = await AxiosCall({
            method: "GET",
            path: "/v1/apps/fetch"
        });

        if (res.status == 1) {
            dispatch(setApps({
                apps: res.data.appList,
                fetchedSites: true,
                isLoading: false,
            }))
        } else {
            dispatch(setApps({
                fetchedSites: true,
                isLoading: false,
            }))
            Message.error(res.message)
        }
    } catch (err: any) {
        dispatch(setApps({
            fetchedSites: true,
            isLoading: false,
        }))
        Message.error(err?.response?.data?.message ?? "An error occurred while fetching apps")
    }
}