import { configureStore } from "@reduxjs/toolkit";
import navigationReducer from '../slices/navigationSlice'
import profileReducer from '../slices/profileSlice'
import appReducer from '../slices/appSlice'
import createAppReducer from '../slices/createAppSlice'
import walletReducer from '../slices/walletSlice'
import debitCardReducer from '../slices/debitCardSlice'
import themeReducer from '../slices/themeSlice'
import projectReducer from '../slices/projectSlice'


export const store = configureStore({
    reducer: {
        navigation: navigationReducer,
        profile: profileReducer,
        apps: appReducer,
        createApp: createAppReducer,
        wallet: walletReducer,
        debitCard: debitCardReducer,
        theme: themeReducer,
        projects: projectReducer
    }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch