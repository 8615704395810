import { Link } from 'react-router-dom';
import styled from 'styled-components'

export const PaymentMethodModal = styled.div`

    h4 {
        text-align: left;
        color: #282828;
    }

    ul {
        display: flex;
        flex-direction: column;
        gap: 20px;

        li {
            display: flex;
            align-items: center;
            cursor: pointer;

            .select-box {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                margin-right: 20px;
                border: 2px solid #cecece;

                ::before {
                    content: "";
                    display: block;
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    background-color: ${props => props.theme.containerBackground};
                }
            }

            .icon-box {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 34px;
                height: 34px;
                margin-right: 15px;
                border-radius: 8px;
                border: 1px solid #ccc;
                background-color: ${props => props.theme.lightPrimaryColor};

                svg {
                    font-size: 20px;
                    color: ${props => props.theme.textPrimaryColor};
                }
            }

            .img-box {
                width: 34px;
                height: 34px;
                margin-right: 15px;
                border-radius: 8px;
                overflow: hidden;

                img {
                    object-fit: cover;
                }
            }

            span {
                font-size: 16px;
                font-weight: 500;
                flex: 1;
            }
        }

        li.selected {
            .select-box {
                border: 2px solid ${props => props.theme.lightPrimaryColor};

                ::before {
                    background-color: ${props => props.theme.primaryButtonColor};
                }
            }
        }

        li.stripe {
            opacity: 0.5;
            span {
                display: flex;
                align-items: center;
                gap: 10px;
                ::after {
                    content: "coming soon";
                    padding: 4px 4px;
                    color: ${props => props.theme.darkTextColor};
                    font-size: 12px;
                    border-radius: 4px;
                    background-color: rgba(77, 64, 64, 0.25);

                }
            }
        }
    }

    button {
        margin-top: 20px;
    }
`;