import React, { useEffect } from 'react'
import { Content, MainLayoutWrapper } from './styles'
import DashboardHeader from '../dashboardHeader/DashboardHeader';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import Sidebar from '../sidebar/Sidebar';
import { fetchProfileAction } from '../../../actions/profileAction';
import { getApps } from '../../../actions/appActions';
import { useLocation } from 'react-router-dom';
import { fetchProjects } from '../../../actions/indexActions';

const MainLayout: React.FC<{title: String, children: React.ReactNode}> = ({title, children}) => {
    // const { height, width } = useWindowDimensions()
    const { pathname } = useLocation();
    const projectsSlice = useAppSelector(state => state.projects.state)
    const userProfile: any = useAppSelector(state => state.profile.state);
    const appSlice: any = useAppSelector(state => state.apps.state);
    const dispatch = useAppDispatch()



    useEffect(() => {
        fetchProjects(dispatch)
    }, [])

    const fetchProfile = async () => {
        if (userProfile.fetchedProfile) {
            return;
        }
        fetchProfileAction(dispatch);
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])
   

    useEffect(() => {
        fetchProfile()

        if (!appSlice.fetchedSites) {
            getApps(dispatch)
        }
    }, [])

    return (
        <MainLayoutWrapper>
            <Sidebar />
            <Content>
                <DashboardHeader title={title} />
                {children}
            </Content>
        </MainLayoutWrapper>
    )
}

export default MainLayout