import { Link } from 'react-router-dom';
import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 100%;
    padding-bottom: 20px;
`;
export const Container = styled.div`
    width: 100%;
    margin-bottom: 20px;
`;
export const StatsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    flex-direction: column;

    @media screen and (min-width: 880px) {
        flex-direction: row;
        /* gap: 4%; */
    }
`;
export const StatsItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;
    background-color: ${props => props.theme.containerBackground};
    padding: 20px 20px;
    border-radius: 6px;
    flex: 1;
    border: 1px solid ${props => props.theme.lightBorderColor};
    width: 100%;

    .icon-box {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
    }

    span {
        :first-of-type {
            font-size: 14px;
            color: ${props => props.theme.lightTextColor};
        }
        :last-of-type {
            font-size: 35px;
            color: ${props => props.theme.darkTextColor};
        }
    }

    :first-child {
        background-color: ${props => props.theme.statsHighlightBackground};

        .icon-box {
            svg {
                color: #eee;
            }
        }
        span {
            :first-of-type {
                color: #eee;
            }
            :last-of-type {
                color: ${props => props.theme.whiteTextColor};
            }
        }
    }

    @media screen and (min-width: 880px) {
        width: calc(25% - 4.5%);
    }
`;

export const SiteInfoWrapper = styled.div`
    background-color: ${props => props.theme.containerBackground};
    border-radius: 4px;
    margin-top: 20px;
    margin-bottom: 0px;
    border: 1px solid ${props => props.theme.lightBorderColor};

    .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        border-bottom: 1px solid ${props => props.theme.lightBorderColor};

        h5 {
            color: ${props => props.theme.textColor};
            font-size: 14px;
            font-weight: 600;
        }

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;
            background-color: ${props => props.theme.primaryButtonColor};
            border: none;
            width: 100px;
            height: 35px;
            border-radius: 4px;
            cursor: pointer;

            span {
                color: ${props => props.theme.primaryButtonTextColor};
                font-size: 12px;
                font-weight: 600;
                text-transform: capitalize;
            }

            svg {
                color: ${props => props.theme.primaryButtonTextColor};
                font-size: 15px;
            }
        }
    }
`;

export const SitesWrapper = styled.div`
    padding: 0px 20px;


    @media screen and (min-width: 880px) {
        
    }
`;

export const SiteItem = styled(Link)`
    display: flex;
    align-items: center;
    padding: 15px 0px;
    gap: 20px;
    .img-wrapper {
        width: 70px;
        height: 60px;
        padding: 10px;
        /* background-color: #eee; */

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .col {
        flex: 1;
        h4 {
            font-size: 13px;
            font-weight: 600;
            margin-bottom: 4px;
            text-transform: capitalize;
            color: ${props => props.theme.darkTextColor};
        }
        span {
            columns: ${props => props.theme.textColor};
            font-size: 12px;
            font-weight: 400;
            color: ${props => props.theme.lightTextColor};
        }
    }

    svg {
        color: ${props => props.theme.darkTextColor};
    }
`;

export const AddSiteModal = styled.div`
    
`;

export const DeploySiteModal = styled.div`
    
`;

export const DeploymentModal = styled.div`
    h5 {
        text-align: center;
    }

    .repo-list {
        margin-top: 20px;
        border: 1px solid #ccc;
        border-radius: 6px;
        height: 100%;
        max-height: 480px;
        overflow-y: scroll;
        
        li {
            display: flex;
            align-items: center;
            padding: 10px 20px;
            border-bottom: 1px solid #ccc;
            color: ${props => props.theme.lightTextColor};
            font-size: 13px;
            font-weight: 600;
            cursor: pointer;

            :hover {
                background-color: #ccc;
            }

            img {
                width: 30px;
                height: 30px;
                object-fit: cover;
                border-radius: 50%;
                margin-right: 10px;
            }


            svg {
                :first-of-type {
                    font-size: 20px;
                    margin-right: 10px;
                }
                :last-of-type {
                    font-size: 15px;
                }
            }


            .icon-box {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                object-fit: cover;
                border-radius: 50%;
                margin-right: 10px;
                background-color: ${props => props.theme.primaryButtonColor};


                svg {
                    font-size: 20px;
                    margin-right: 0px;
                    color: ${props => props.theme.primaryButtonTextColor};
                }
            }

            span {
                flex: 1;
            }

            :last-of-type {
                border-bottom: none;
            }
        }
    }

    .branch-list {
        margin-top: 20px;
        border: 1px solid #ccc;
        border-radius: 6px;
        height: 100%;
        max-height: 480px;
        overflow-y: scroll;
        
        li {
            display: flex;
            align-items: center;
            padding: 10px 20px;
            border-bottom: 1px solid #ccc;
            color: ${props => props.theme.lightTextColor};
            font-size: 13px;
            font-weight: 600;
            cursor: pointer;

            :hover {
                background-color: #ccc;
            }

            svg {
                :first-of-type {
                    font-size: 20px;
                    margin-right: 10px;
                }
                :last-of-type {
                    font-size: 15px;
                }
            }

            span {
                flex: 1;
            }

            :last-of-type {
                border-bottom: none;
            }
        }

        .selcted-repo {
            span {
                color: ${props => props.theme.darkTextColor};
            }
            :hover {
                background-color: transparent;
                cursor: default;
            }
        }

        .selected-branch {
            background-color: #ccc;
        }
    }

    .deploy-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${props => props.theme.primaryButtonTextColor};
        margin-left: auto;
        margin-top: 20px;
        width: 100px;
        height: 45px;
        background-color: ${props => props.theme.primaryButtonColor};
        border-radius: 4px;
        cursor: pointer;
    }
`;


export const EmptyContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px 20px;
    gap: 40px;
    /* background-color: #ceceec66; */
    border-radius: 8px;

    button {
        background-color: ${props => props.theme.primaryButtonColor};
        color: ${props => props.theme.primaryButtonTextColor};
        border: none;
        border-radius: 4px;
        height: 45px;
        width: 150px;
        text-transform: capitalize;
        cursor: pointer;
    }

    .text-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        border-radius: 8px;

        svg {
            width: 30px;
            height: 30px;
            color: ${props => props.theme.darkTextColor};
        }

        span {
            color: ${props => props.theme.darkTextColor};
            font-size: 20px;
            font-weight: 600;
            text-align: center;
        }

        p {
            color: ${props => props.theme.darkTextColor};
            font-size: 16px;
            font-weight: 400;
            text-align: center;
        }
    }

    @media screen and (min-width: 880px) {
        
    }
`;


export const SubscriptionModal = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar, ::-webkit-scrollbar-track, ::-webkit-scrollbar-thumb {
        display: none;
    }

    h4 {
        color: #282828;
    }

    @media screen and (min-width: 880px) {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: unset;
    }
`;

export const SubscriptionCard = styled.div<{isSelected: boolean}>`
    display: flex;
    flex-direction: column;
    background-color: ${props => props.theme.containerBackground};
    border: 0.5px solid ${props => props.isSelected ? props.theme.primaryColor : "#ccc"};
    cursor: pointer;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    max-width: 480px;

    h3 {
        margin-bottom: 20px;
    }

    span.price {
        font-size: 40px;
        font-weight: 400;
        color: ${props => props.theme.darkTextColor};
    }

    p.notice {
        font-size: 13px;
        color: #282828;
        margin-top: 7px;
        margin-bottom: 20px;
    }

    ul {
        margin-bottom: 20px;
        li {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-top: 20px;

            span.check-box {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                background-color: #282828;
                color: ${props => props.theme.whiteTextColor};
                font-size: 13px;
            }

            span.title {
                font-size: 13px;
                font-weight: 500;
                color: #282828;
            }
        }
    }

    button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        padding: 0px 20px;
        margin-top: auto;
        border-radius: 8px;
        background-color: ${props => props.isSelected ? props.theme.primaryColor : "transparent"};
        color: ${props => props.isSelected ? "#fff" : "#ccc"};
        border: 1px solid ${props => props.isSelected ? props.theme.primaryColor : "#ccc"};

        span {
            font-weight: 400;
        }

        svg {
            font-size: 18px;
        }
    }

    @media screen and (min-width: 880px) {
        width: calc(50% - 10px);
        max-width: unset;
    }

    @media screen and (min-width: 1080px) {
        width: calc(33.33% - 20px);
    }
`;

