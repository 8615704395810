import {createSlice, PayloadAction} from '@reduxjs/toolkit';

const initialState: {state: ProjectSliceInterface} = {
  state: {
    projects: [],
    isLoading: true,
    fetchedProject: false,
  }
};

export const setProjectSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setProjects: (state, action: PayloadAction<ProjectSliceInterface>) => {
      state.state = {...state.state, ...action.payload};
    },
  },
});

export const { setProjects } = setProjectSlice.actions;

export interface ProjectSliceInterface {
  projects?: {
    id?:           string;
    name?:         string;
    user_id?:      string;
    permissions?:  string;
    members?:      string;
    description?:  string;
    purpose?:      string;
    meta?:         string;
    date_created?: Date;
    date_updated?: Date;
  }[]
  fetchedProject?: boolean,
  isLoading?: boolean,
}

export default setProjectSlice.reducer;
