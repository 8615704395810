import { Button, Container, Form, FormMeta, InputSec, MainSection, PasswordInput, Sidebar, Wrapper } from './styles'
import { Link, useNavigate } from 'react-router-dom'
import { AiOutlineEye, AiOutlineEyeInvisible, AiOutlineGithub } from 'react-icons/ai'
import { useRef, useState } from 'react'
import AxiosCall from '../../../../utils/axios'
import Message from '../../../components/message/Message'
import Loader from '../../../components/Loader/Loader'
import useQuery from '../../../../hooks/useQuery'
import { useDispatch } from 'react-redux'
import { setProfile } from '../../../../slices/profileSlice'
import { getApps } from '../../../../actions/appActions'


const SigninScreen: React.FC = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const email = useRef<HTMLInputElement>(null);
    const password = useRef<HTMLInputElement>(null);

    const query = useQuery()

    const signin = async (e: any) => {
        e.preventDefault();
        setIsLoading(true)

        try {
            if (!password?.current?.value.length) {
                setIsLoading(false)
                return Message.error("Passwords can't be empty");
            }
            const res: any = await AxiosCall({
                method: "POST",
                path: "/v1/signin",
                data: {
                    email: email?.current?.value,
                    password: password?.current?.value
                }
            });

            if (res.status == 1) {
                setIsLoading(false)
                Message.success("Login success")
                localStorage.setItem("authToken", res.data.token)
                let redirectPath = query.get('redirect')

                dispatch(setProfile({
                    email: res.data.email,
                    firstName: res.data.firstName,
                    lastName: res.data.lastName,
                    balance: res.data.balance,
                    fetchedProfile: true,
                }))

                getApps(dispatch)

                if (redirectPath) {
                    const decodedURl = decodeURIComponent(redirectPath)
                    return navigate(decodedURl);
                }

                return navigate("/dashboard");
            } else {
                setIsLoading(false)
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsLoading(false)
            Message.error(err?.response.data.message)
        }
    }

    const CLIENT_ID = "22e850a6ac69e54e5b76";

    const githubSignIn = (e: any) => {
        e.preventDefault()

        window.location.assign("https://github.com/login/oauth/authorize?client_id=" + CLIENT_ID)
    }



    return (
        <Wrapper>
            <Container>
                <Sidebar>
                    
                </Sidebar>
                <MainSection>
                    <Form>
                        {/* <h1>Welcome back!</h1> */}
                        <div className="github-auth" onClick={githubSignIn}>
                            <div className="github-box">
                                <AiOutlineGithub />
                            </div>
                            <span>Sign in with github</span>
                        </div>
                        <div className="email-auth">
                            <span>Or sign in with your email</span>
                        </div>
                        <InputSec>
                            <label htmlFor="user-email">Email</label>
                            <input ref={email} id="user-email" type="text" placeholder="example@email.com" />
                        </InputSec>
                        <PasswordInput>
                            <label htmlFor="user-pass">Password</label>
                            <div className="input-row">
                                <input ref={password} id="user-pass" type={showPassword ? "text" : "password"} placeholder="Enter password" />
                                {showPassword ? <AiOutlineEyeInvisible onClick={() => setShowPassword(!showPassword)} /> : <AiOutlineEye onClick={() => setShowPassword(!showPassword)} />}
                            </div>
                        </PasswordInput>
                        <FormMeta>
                            <a href="#">Forgot Password?</a>
                        </FormMeta>
                        <Button onClick={signin}>{isLoading ? <Loader /> : "Sign In"}</Button>

                        <div className="has-acct-tow">
                            <span>Don't have an account? </span>
                            <Link to="/signup">Sign up</Link>
                        </div>
                    </Form>
                </MainSection>
            </Container>
        </Wrapper>
    )
}

export default SigninScreen