import { useDispatch } from 'react-redux';
import { HeadRow, Wrapper } from './styles'
import { useAppSelector } from '../../../../hooks/hooks';
import Message from '../../../components/message/Message';
import AxiosCall from '../../../../utils/axios';
import { useRef, useState } from 'react';
import { setProfile } from '../../../../slices/profileSlice';
import { Link } from 'react-router-dom';
import { FaUserAlt } from 'react-icons/fa';
import { HiUserGroup } from 'react-icons/hi';
import { VscSettings } from 'react-icons/vsc';
import ProfileTab from './profile_tab/ProfileTab';
import TeamsTab from './teams_tab/TeamsTab';
import PreferencesTab from './preferences_tab/PreferencesTab';
import { FiMail } from 'react-icons/fi';
import InvitesTab from './invites_tab/InvitesTab';

const SettingsScreen: React.FC = () => {
    const userProfile = useAppSelector(state => state.profile.state);
    const dispatch = useDispatch();
    const [selectedTab, setSelectedTab] = useState("profile")

    const [isLoading, setIsLoading] = useState<boolean>(false)

    const firstNameRef = useRef<HTMLInputElement>(null)
    const lastNameRef = useRef<HTMLInputElement>(null)
    const phoneRef = useRef<HTMLInputElement>(null)

    const updateProfile = async () => {
        try {
            setIsLoading(true)
            
            const res: any = await AxiosCall({
                method: "PATCH",
                path: "/v1/profile/update",
                data: {
                    first_name: firstNameRef.current?.value,
                    last_name: lastNameRef.current?.value,
                    phone: phoneRef.current?.value,
                }
            });

            setIsLoading(false)
            if (res.status == 1) {
                dispatch(setProfile({
                    firstName: res.data.firstname,
                    lastName: res.data.lastname,
                    phone: res.data.phone
                }))
                Message.success(res.message)
            } else {
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsLoading(false)
            Message.error(err?.response?.data?.message ?? "An error occurred")
        }
    }

    return (
        <Wrapper>
            <HeadRow>
                <li>
                    <Link className={selectedTab == "profile" ? "active" : ""} to={"#"} onClick={() => {setSelectedTab("profile")}}><FaUserAlt />Profile</Link>
                </li>
                <li>
                    <Link className={selectedTab == "teams" ? "active" : ""} to="#" onClick={() => {setSelectedTab("teams")}}><HiUserGroup />Teams</Link>
                </li>
                <li>
                    <Link className={selectedTab == "preferences" ? "active" : ""} to="#" onClick={() => {setSelectedTab("preferences")}}><VscSettings />Preferences</Link>
                </li>
                <li>
                    <Link className={selectedTab == "invites" ? "active" : ""} to="#" onClick={() => {setSelectedTab("invites")}}><FiMail />Invites</Link>
                </li>
            </HeadRow>
            
            {selectedTab == "profile" ? <ProfileTab />
            : selectedTab == "teams"? <TeamsTab />
            : selectedTab == "invites"? <InvitesTab />
            : <PreferencesTab /> }
        </Wrapper>
    )
}

export default SettingsScreen