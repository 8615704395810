import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 100%;
`;
export const Container = styled.div`
    /* padding: 0px 6%;
    @media screen and (min-width: 880px) {
        padding: 0px 4%;
    } */
    width: 100%;
`;
export const MainRow = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;


    @media screen and (min-width: 1080px) {
        flex-direction: row;
        align-items: flex-start;
    }
`;

export const SitesInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 20px;
    margin: 20px 0px;
`;

export const SitesWrapper = styled.div`
    background-color: ${props => props.theme.containerBackground};
    border: 1px solid ${props => props.theme.lightBorderColor};
    margin: 0px 0px;
    flex: 1;
    border-radius: 4px;

    .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        border-bottom: 1px solid ${props => props.theme.lightBorderColor};

        .col {
            display: flex;
            flex-direction: column;
            gap: 8px;
            align-items: flex-start;

            h5 {
                color: ${props => props.theme.darkTextColor};
                font-size: 16px;
                font-weight: 600;
                text-transform: capitalize;
            }

            .row {
                display: flex;
                align-items: center;
                gap: 10px;

                b {
                    font-size: 12px;
                    color: ${props => props.theme.darkTextColor};
                }

                span {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 3px 6px;
                    font-size: 10px;
                    border-radius: 3px;
                    background-color: rgb(194, 253, 194);
                    color: #090;
                    /* border: 0.5px solid #090; */
                }

                .disabled {
                    background-color: rgb(245, 187, 187);
                    color: #900;
                }

                .booting, .mounting, .updating {
                    background-color: rgb(205, 187, 255);
                    color: rgb(61, 19, 177);
                }
            }
        }

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;
            background-color: ${props => props.theme.primaryButtonColor};
            border: none;
            width: 90px;
            height: 35px;
            border-radius: 4px;
            cursor: pointer;

            span {
                color: ${props => props.theme.primaryButtonTextColor};
                font-size: 12px;
                font-weight: 600;
                text-transform: capitalize;
            }

            svg {
                color: ${props => props.theme.primaryButtonTextColor};
                font-size: 15px;
            }
        }
    }

    .content {
        padding: 20px 20px;
        h2 {
            color: ${props => props.theme.textPrimaryColor};
            font-size: 17px;
            font-weight: 700;
            margin-bottom: 15px;
        }

        ul {
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin-bottom: 20px;

            b {
                color: ${props => props.theme.darkTextColor};
                font-weight: 600;
                font-size: 14px;
            }
            li {
                color: ${props => props.theme.lightTextColor};
                font-size: 15px;
                font-weight: 400;

                a {
                    display: flex;
                    gap: 2px;
                    color: ${props => props.theme.lightTextColor};
                }
            }

            .row {
                display: flex;
                gap: 10px;
                input {
                    border: 1px solid ${props => props.theme.lightBorderColor};
                    background-color: ${props => props.theme.containerBackground};
                    color: ${props => props.theme.darkTextColor};
                    height: 35px;
                    padding: 0px 10px;
                    font-size: 13px;
                    border-radius: 4px;
                    width: 100px;
                }

                button {
                    width: 100px;
                    border-radius: 4px;
                    color: ${props => props.theme.primaryButtonTextColor};
                    font-size: 14px;
                    font-weight: 500;
                    border: none;
                    background-color: ${props => props.theme.primaryButtonColor};
                    cursor: pointer;
                }
                span {
                    padding: 4px 3px;
                    border-radius: 4px;
                    color: ${props => props.theme.whiteTextColor};
                    font-size: 10px;
                    font-weight: 500;
                    border: none;
                    color: ${props => props.theme.secondaryColor};
                }
            }
        }


        .deploy-meta {
            display: flex;
            flex-direction: column;
            padding-top: 4px;

            li {
                display: flex;
                align-items: center;
                gap: 4px;
                color: ${props => props.theme.darkTextColor};
                font-weight: 400;
                margin-bottom: 4px;
            }
            button {
                width: 70px;
                height: 30px;
                margin-top: 4px;
                background-color: ${props => props.theme.containerBackground};
                border-radius: 4px;
                color: ${props => props.theme.textPrimaryColor};
                font-size: 13px;
                font-weight: 500;
                border: none;
                border: 1px solid ${props => props.theme.primaryColor};
                cursor: pointer;
            }
        }

        .env-container {
            h2 {
                margin-bottom: 30px;
            }

            ul {
                display: flex;
                align-items: center;
                flex-direction: row;
                gap: 20px;
                
                span {
                    font-size: 20px;
                    font-weight: 600;
                    color: ${props => props.theme.textColor};
                }

                input {
                    display: flex;
                    position: relative;
                    justify-content: center;
                    /* text-align: center; */
                    padding: 0px 15px;
                    flex: 1;
                    height: 40px;
                    width: 100%;
                    max-width: 220px;
                    border-radius: 4px;
                    font-weight: 400;
                    font-weight: 400;
                    border: 1px solid ${props => props.theme.lightBorderColor};
                    background-color: ${props => props.theme.backgroudColor};
                    color: ${props => props.theme.darkTextColor};

  

                    :first-child {
                        background-color: ${props => props.theme.containerBackground};
                        font-weight: 600;
                        max-width: 150px;
                    }
                }
            }

            .actions {
                display: flex;
                gap: 30px;

                button {
                    width: 100px;
                    height: 44px;
                    border-radius: 3px;
                    background-color: ${props => props.theme.containerBackground};
                    text-transform: capitalize;
                    color: ${props => props.theme.textPrimaryColor};
                    border: 1px solid ${props => props.theme.primaryColor};
                    cursor: pointer;

                    /* :last-child {
                        color: ${props => props.theme.primaryButtonTextColor};
                        background-color: ${props => props.theme.primaryButtonColor};
                    } */
                }
            }
        }

    }

    @media screen and (min-width: 880px) {
        
    }
`;

export const SettingsWrapper = styled.div`
    background-color: ${props => props.theme.containerBackground};
    border: 1px solid ${props => props.theme.lightBorderColor};
    margin: 0px 0px;
    flex: 1;
    border-radius: 4px;

    .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        border-bottom: 1px solid ${props => props.theme.lightBorderColor};
        margin-bottom: 10px;

        h2 {
            color: ${props => props.theme.textPrimaryColor};
            font-size: 17px;
            font-weight: 700;
        }

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;
            background-color: rgb(224, 31, 31);
            border: none;
            width: 90px;
            height: 35px;
            border-radius: 4px;
            cursor: pointer;

            span {
                color: ${props => props.theme.whiteTextColor};
                font-size: 12px;
                font-weight: 600;
                text-transform: capitalize;
            }

            svg {
                color: ${props => props.theme.whiteTextColor};
                font-size: 15px;
            }
        }
    }

    .content {
        padding: 15px 20px;
  
        ul {
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin-bottom: 20px;

            b {
                color: ${props => props.theme.darkTextColor};
                font-weight: 600;
                font-size: 14px;
            }
            li {
                color: ${props => props.theme.lightTextColor};
                font-size: 15px;
                font-weight: 400;

                a {
                    display: flex;
                    gap: 2px;
                    color: ${props => props.theme.lightTextColor};
                }
            }

            .row {
                display: flex;
                gap: 10px;
                input {
                    border: 1px solid ${props => props.theme.lightBorderColor};
                    background-color: ${props => props.theme.backgroudColor};
                    color: ${props => props.theme.darkTextColor};
                    height: 35px;
                    padding: 0px 10px;
                    font-size: 13px;
                    border-radius: 4px;
                    width: 100px;
                }

                button {
                    width: 100px;
                    border-radius: 4px;
                    color: ${props => props.theme.primaryButtonTextColor};
                    font-size: 14px;
                    font-weight: 500;
                    border: none;
                    background-color: ${props => props.theme.primaryButtonColor};
                    cursor: pointer;
                }
                span {
                    padding: 4px 3px;
                    border-radius: 4px;
                    color: ${props => props.theme.whiteTextColor};
                    font-size: 10px;
                    font-weight: 500;
                    border: none;
                    color: ${props => props.theme.secondaryColor};
                }
            }
        }


        .deploy-meta {
            display: flex;
            flex-direction: column;
            padding-top: 4px;

            li {
                display: flex;
                align-items: center;
                gap: 4px;
                color: ${props => props.theme.darkTextColor};
                font-weight: 400;
                margin-bottom: 4px;
            }
            button {
                width: 70px;
                height: 30px;
                margin-top: 4px;
                background-color: ${props => props.theme.containerBackground};
                border-radius: 4px;
                color: ${props => props.theme.textPrimaryColor};
                font-size: 13px;
                font-weight: 500;
                border: none;
                border: 1px solid ${props => props.theme.primaryColor};
                cursor: pointer;
            }
        }
    }

    @media screen and (min-width: 880px) {
        
    }
`;

export const SideBar = styled.div`
    margin: 20px 0px;
    width: 100%;

    .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 15px;
        border-bottom: 1px solid ${props => props.theme.lightBorderColor};

        .col {
            display: flex;
            flex-direction: column;
            gap: 8px;
            align-items: flex-start;
            h5 {
                color: ${props => props.theme.darkTextColor};
                font-size: 16px;
                font-weight: 600;
                text-transform: capitalize;
            }

            .row {
                display: flex;
                align-items: center;
                gap: 10px;

                b {
                    font-size: 12px;
                    color: ${props => props.theme.darkTextColor};
                }

                span {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 3px 6px;
                    font-size: 10px;
                    border-radius: 3px;
                    background-color: rgb(194, 253, 194);
                    color: #090;
                    /* border: 0.5px solid #090; */
                }
            }
        }

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;
            background-color: ${props => props.theme.primaryButtonColor};
            border: none;
            width: 90px;
            height: 35px;
            border-radius: 4px;
            cursor: pointer;

            span {
                color: ${props => props.theme.primaryButtonTextColor};
                font-size: 12px;
                font-weight: 600;
                text-transform: capitalize;
            }

            svg {
                color: ${props => props.theme.primaryButtonTextColor};
                font-size: 15px;
            }
        }
    }
    
    @media screen and (min-width: 1080px) {
        width: 30%;
        min-width: 320px;
    }
`;


export const BuildWrapper = styled.div`
    background-color: ${props => props.theme.containerBackground};
    margin-bottom: 20px;
    border: 1px solid ${props => props.theme.lightBorderColor};
    border-radius: 4px;
    /* min-height: 400px; */
    width: 100%;

    .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 20px;
        border-bottom: 1px solid ${props => props.theme.lightBorderColor};

        h5 {
            color: ${props => props.theme.darkTextColor};
            font-size: 14px;
            font-weight: 600;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        padding: 0px 20px;
    }
`;


export const LogWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${props => props.theme.containerBackground};
    border: 1px solid ${props => props.theme.lightBorderColor};
    border-radius: 4px;
    min-height: 400px;
    width: 100%;
    overflow: hidden;

    .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 20px;
        border-bottom: 1px solid ${props => props.theme.lightBorderColor};

        h5 {
            color: ${props => props.theme.darkTextColor};
            font-size: 14px;
            font-weight: 600;
        }

        .expand {
            color: ${props => props.theme.darkTextColor};
            cursor: pointer;
        }
    }

    .content {
        display: flex;
        background-color: #223;
        margin-top: 20px;
        border-radius: 4px;
        max-width: 100%;
        height: 340px;
        padding: 0px 20px;

        ::before {
            content: "";
            width: 40px;
            background-color: #000;
        }

        .logs-wrapper {
            overflow: hidden;
            height: 100%;
            flex: 1;
            .logs {
                flex: 1;
                height: 100%;
                overflow: auto;

                p {
                    color: #f7f7f7;
                    font-size: 14px;
                    line-height: 24px;
                    padding: 20px;
                    white-space: nowrap;
                }
            }
        }
    }
`;


export const LogsModal = styled.div`
    .content {
        display: flex;
        background-color: #223;
        margin-top: 20px;
        border-radius: 4px;
        height: calc(100vh - 200px);

        ::before {
            content: "";
            width: 40px;
            background-color: #000;
        }

        .logs-wrapper {
            overflow: hidden;
            height: 100%;
            flex: 1;
            .logs {
                flex: 1;
                height: 100%;
                overflow: auto;

                p {
                    color: #f7f7f7;
                    font-size: 14px;
                    line-height: 24px;
                    padding: 20px;
                    white-space: nowrap;
                }
            }
        }
    }
`;


export const AddSiteModal = styled.div`
    
`;

export const DeploySiteModal = styled.div`
    
`;

export const DeploymentModal = styled.div`
    h5 {
        text-align: center;
    }

    .repo-list {
        margin-top: 20px;
        border: 1px solid #ccc;
        border-radius: 6px;
        height: 100%;
        max-height: 480px;
        overflow-y: scroll;
        
        li {
            display: flex;
            align-items: center;
            padding: 10px 20px;
            border-bottom: 1px solid #ccc;
            color: ${props => props.theme.lightTextColor};
            font-size: 13px;
            font-weight: 600;
            cursor: pointer;

            :hover {
                background-color: #ccc;
            }

            img {
                width: 30px;
                height: 30px;
                object-fit: cover;
                border-radius: 50%;
                margin-right: 10px;
            }


            svg {
                :first-of-type {
                    font-size: 20px;
                    margin-right: 10px;
                }
                :last-of-type {
                    font-size: 15px;
                }
            }


            .icon-box {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                object-fit: cover;
                border-radius: 50%;
                margin-right: 10px;
                background-color: ${props => props.theme.primaryButtonColor};


                svg {
                    font-size: 20px;
                    margin-right: 0px;
                    color: ${props => props.theme.primaryButtonTextColor};
                }
            }

            span {
                flex: 1;
            }

            :last-of-type {
                border-bottom: none;
            }
        }
    }

    .branch-list {
        margin-top: 20px;
        border: 1px solid #ccc;
        border-radius: 6px;
        height: 100%;
        max-height: 480px;
        overflow-y: scroll;
        
        li {
            display: flex;
            align-items: center;
            padding: 10px 20px;
            border-bottom: 1px solid #ccc;
            color: ${props => props.theme.lightTextColor};
            font-size: 13px;
            font-weight: 600;
            cursor: pointer;

            :hover {
                background-color: #ccc;
            }

            svg {
                :first-of-type {
                    font-size: 20px;
                    margin-right: 10px;
                }
                :last-of-type {
                    font-size: 15px;
                }
            }

            span {
                flex: 1;
            }

            :last-of-type {
                border-bottom: none;
            }
        }

        .selcted-repo {
            span {
                color: ${props => props.theme.darkTextColor};
            }
            :hover {
                background-color: transparent;
                cursor: default;
            }
        }

        .selected-branch {
            background-color: #ccc;
        }
    }

    .deploy-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${props => props.theme.primaryButtonTextColor};
        margin-left: auto;
        margin-top: 20px;
        width: 100px;
        height: 45px;
        background-color: ${props => props.theme.primaryButtonColor};
        border-radius: 4px;
        cursor: pointer;
    }
`;


export const SiteBuilds = styled.div<{status: string}>`
    display: flex;
    align-items: center;
    padding: 15px 0px;
    gap: 20px;
    cursor: pointer;

    .img-wrapper {
        svg {
            width: 40px;
            height: 40px;
            color: ${props => props.theme.darkTextColor};
        }
    }
    .col {
        flex: 1;
        h4 {
            font-size: 13px;
            font-weight: 600;
            margin-bottom: 4px;
            color: ${props => props.theme.darkTextColor};
            text-transform: capitalize;
        }
        span {
            columns: ${props => props.theme.textColor};
            font-size: 12px;
            font-weight: 400;
            color: ${props => props.theme.darkTextColor};
            font-style: italic;
        }
    }

    .meta {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap:  10px;
        align-self: flex-start;
        span {
            display: flex;
            padding: 5px 10px;
            font-size: 12px;
            border-radius: 4px;
            background-color: ${props => props.status == "completed" ? "rgb(194, 253, 194)" : props.status == "failed" ? "rgb(245, 187, 187)" : props.status == "queued" ? props.theme.lightPrimaryColor : "rgb(255, 228, 182)"};
            color: ${props => props.status == "completed" ? "#090" : props.status == "failed" ? "#900" : props.status == "queued" ? props.theme.primaryColor : "#ff6633"};
            border: 1px solid ${props => props.status == "completed" ? "#090" : props.status == "failed" ? "#900" : props.status == "queued" ? props.theme.primaryColor : "#ff6633"};
            
        }
    }
`;


export const DeployLogModal = styled.div`
    background-color: #ceceec66;
    padding: 20px;
    border-radius: 8px;
    font-size: 14px;
    line-height: 23px;
    white-space: nowrap;
    overflow: auto;
`;

export const GraphWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 15px 20px;

    @media screen and (min-width: 880px) {
        flex-direction: row;
        flex-wrap: wrap;
    }
`;

export const GrapContainer = styled.div<{color: string}>`
    width: 100%;
    /* height: 400px; */
    position: relative;
    @media screen and (min-width: 880px) {
        width: calc(50% - 10px);
    }

    .title {
        display: block;
        color: ${props => props.theme.darkTextColor};
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 10px;
    }

    .stats {
        display: flex;
        flex-direction: column;
        border: 1px solid ${props => props.theme.lightBorderColor};
        /* border-left: none; */
        margin-top: 20px;
        border-radius: 4px;

        ul {
            display: flex;
            align-items: center;
            height: 40px;
            li {
                display: flex;
                align-items: center;
                flex: 1;
                border-left: 1px solid ${props => props.theme.lightBorderColor};
                height: 100%;
                padding: 0px 20px;
                font-size: 14px;
                font-weight: 600;
                color: ${props => props.theme.darkTextColor};
                :first-child {
                    flex: 2;
                    border-left: none;
                }
            }
            :first-child {
                background-color: ${props => props.theme.backgroudColor};
                height: 45px;
                border-bottom: 1px solid ${props => props.theme.lightBorderColor};
            }
            :last-child {
                li {
                    border-left: none;
                    font-size: 14px;
                    font-weight: 400;
                    :first-child {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        font-size: 13px;
                        font-weight: 500;

                        ::before {
                            content: "";
                            display: block;
                            height: 20px;
                            width: 20px;
                            border-radius: 4px;
                            background-color: ${props => props.color};
                        }
                    }
                }
            }
        }
    }
`;

export const GraphBox = styled.div<{background: string}>`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    position: relative;
    background-color: ${props => props.background};
    padding: 10px;
    border-radius: 4px;
`;

export const MountingWrapper = styled.div<{progress: string}>`
    display: flex;
    position: relative;
    width: 100%;
    height: 10px;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 10px;
    background-color: ${props => props.theme.lightPrimaryColor};

    ::before {
        content: "";
        display: block;
        height: 100%;
        width: ${props => props.progress};
        background-color: ${props => props.theme.primaryButtonColor};
        position: absolute;
        left: 0px;
        top: 0px;
        border-radius: 5px;
        transition: all 0.8s ease-in-out;
        animation: booter 4s ease-in-out infinite;
    }

    @keyframes booter {
        0% {
            opacity: 0.5;
        }

        33% {
            opacity: 1;
        }

        100% {
            opacity: 0.5;
        }
    }
`;


export const DeleteModal = styled.div`
    .icon-box {
        display: flex;
        justify-content: center;
        svg {
            font-size: 60px;
            color: rgb(224, 31, 31);
        }
    }

    h4 {
        color: #282828;
        font-size: 17px;
        text-transform: capitalize;
        margin: 10px 0px 10px 0px;
    }

    p.warning {
        color: #282828;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 20px;
        text-align: center;
    }

    .notice {
        text-align: center;
        margin: 0px auto 20px auto;
        padding: 10px 10px;
        border-radius: 4px;
        border: 1px solid #282828;

        p {
            font-size: 12px;
            color: #282828;
            font-weight: 400;

            span {
                display: inline-block;
                margin: 0px 5px 0px 5px;
                /* background-color: #eee; */
                font-weight: 600;
                color: #282828;
                padding: 1px 4px;
                border-radius: 3px;
                /* border: 1px solid #5d5b5b; */
            }
        }
    }

    button {
        background-color: rgb(224, 31, 31);

        :disabled {
            background-color: rgb(224, 31, 31, 0.5);
        }
    }
`;