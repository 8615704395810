import React from 'react';
import { Content, HeaderWrapper, HeadMeta, Title } from './styles';
import { RiSearch2Line } from 'react-icons/ri';
import { IoMdNotificationsOutline } from 'react-icons/io';
import { IoChevronDown, IoMenuOutline } from 'react-icons/io5';
import { CgProfile } from 'react-icons/cg';
import { MdArrowDropDown } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { isPropertySignature } from 'typescript';
import { setNavigation } from '../../../slices/navigationSlice';
import { useAppSelector } from '../../../hooks/hooks';
import { FaUserCircle } from 'react-icons/fa';
import { FiSearch } from 'react-icons/fi';
import { useNavigate } from 'react-router';

const  DashboardHeader: React.FC<{title: String}> = ({title}) => {
    const userProfile = useAppSelector(state => state.profile.state);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return (
        <HeaderWrapper>
            <Content>
                <div className="left-col">
                    <div className="toggle-menu" onClick={() => dispatch(setNavigation({dashboard: true}))}>
                        <IoMenuOutline />
                    </div>
                    <div className="search-bar">
                        <FiSearch />
                        <input type="search" placeholder="Search for resource" />
                    </div>
                </div>
                <Title>
                    {title}
                </Title>

                <div className="right-col">
                    <button className="create-btn" onClick={() => navigate("/dashboard/projects/new")}>
                        <span>Create</span>
                        <IoChevronDown />
                    </button>
                    <HeadMeta>
                        <div className="notification-wrapper">
                            <img src="/assets/img/avatar-alt.png" alt="avatar" />
                        </div>
                        <div className="acct-info">
                            <span>{userProfile.firstName}</span>
                            <span>My Project</span>
                        </div>
                        <IoChevronDown />
                    </HeadMeta>
                </div>
            </Content>
        </HeaderWrapper>
    )
}

export default DashboardHeader