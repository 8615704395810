import { Button, Container, Form, FormMeta, InputSec, MainSection, PasswordInput, Sidebar, Wrapper } from './styles'
import Header from '../../../components/header/Header'
import Footer from '../../../components/footer/Footer'
import { Link, useNavigate } from 'react-router-dom'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { useEffect, useRef, useState } from 'react'
import AxiosCall from '../../../../utils/axios'
import Message from '../../../components/message/Message'
import Loader from '../../../components/Loader/Loader'
import useQuery from '../../../../hooks/useQuery'
import { useDispatch } from 'react-redux'
import { HiOutlineHome } from 'react-icons/hi'


const AuthScreen: React.FC = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [error, setError] = useState("")

    const email = useRef<HTMLInputElement>(null);
    const password = useRef<HTMLInputElement>(null);

    const query = useQuery()
    const authenticate = async () => {

        const auth_code = query.get('code');

        try {
            setIsLoading(true)
            const res: any = await AxiosCall({
                method: "POST",
                path: "/v1/social/signup?code=" + auth_code
            });

            if (res.status == 1) {
                setIsLoading(false)
                Message.success(res.message)
                localStorage.setItem("authToken", res.data.token)
                return navigate("/dashboard");
            } else {
                setIsLoading(false)
                setError(res.message)
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsLoading(false)
            setError(err?.response?.data?.message ?? "An error occurred")
            Message.error(err?.response?.data?.message ?? "An error occurred")
        }
    }

    useEffect(() => {
        authenticate()
    }, [])

    return (
        <Wrapper>
            <Container>
                <Sidebar>
                    <div className="content">
                        {error == "" || isLoading ? <div className="text-content">
                            <img src="/assets/img/logo.png" alt="site logo"/>
                            <h4>Authorizing ...</h4>
                        </div> : <div className="error">
                            <h4>Error: {error}</h4>

                            <Link to="/"><HiOutlineHome /> home</Link>
                        </div>}
                        {isLoading && <Loader />}
                    </div>
                </Sidebar>
            </Container>
        </Wrapper>
    )
}

export default AuthScreen