import { setProjects } from "../slices/projectSlice";
import AxiosCall from "../utils/axios";
import Message from "../views/components/message/Message";

export const fetchProjects = async (dispatch: any) => {
    try {
        const res: any = await AxiosCall({
            method: "GET",
            path: "/v1/projects/fetch"
        });

        dispatch(setProjects({
            isLoading: false,
        }))

        if (res.status == 1) {
            setProjects(res.data ?? [])
            dispatch(setProjects({
                projects: res.data,
                fetchedProject: true,
                isLoading: false,
            }))
        } else {
            Message.error(res.message)
        }
    } catch (err: any) {
        dispatch(setProjects({
            isLoading: false,
        }))
        Message.error(err?.response?.data?.message ?? "An error occurred while fetching projects")
    }
}